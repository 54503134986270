$enable-shadows: true;
$enable-gradients: true;

@import '~bootstrap/scss/bootstrap.scss';

.openbutton {
    background-color: #04AA6D; /* Green */
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    //display: inline-block;
    font-size: 24px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 50%;
    height: 150px;
  }
  